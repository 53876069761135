
  import React from 'react';
  import COMPONENTS from '../components';
  import { useCustomLink } from "nbt-uikit";
  import CustomLink from '../utils/link';
  import { get } from 'lodash';
  import PageLayout from '../layout';
  import { graphql } from 'gatsby';

  useCustomLink(CustomLink);

  class Page extends React.Component {
    state = {
      data: [],
    };

    componentDidMount() {
      let slices;

      const data = get(this, 'props.data.indexJson');

      if (data) {
        slices = Object.entries(data).map(([key, content]) => ({
          componentName: key,
          ...content,
        }));

        slices.sort(
          ({ position: positionA }, { position: positionB }) =>
            positionA - positionB
        );

        this.setState({
          data: slices,
        });
      }
    }

    render() {
      return (
        <PageLayout>
          {get(this, 'state.data').map(({ componentName, data }, index) => {
            const ComponentType = COMPONENTS[componentName];
            return <ComponentType key={index} {...data} />;
          })}
        </PageLayout>
      );
    }
  }

  export default Page;

  export const pageQuery = graphql`
    query {
      indexJson {
        
      
      
      
      
      
      
      
      
      HeroWithOverlay0 {
        position
        componentName
        data {
           image title textLayout buttonPrimary {  text link }
        }
      }
    
      Header1 {
        position
        componentName
        data {
           background type subtitle showRedLine
        }
      }
    
      Divider2 {
        position
        componentName
        data {
           height
        }
      }
    
      NestedPageSection3 {
        position
        componentName
        data {
           slices {  componentName data {  slides {  image title textBody } } } uid
        }
      }
    
      NestedPageSection4 {
        position
        componentName
        data {
           slices {  componentName data {  title textBody image } } uid
        }
      }
    
      NestedPageSection5 {
        position
        componentName
        data {
           slices {  componentName data {  header iconGroup {  social } } } uid
        }
      }
    
      NestedPageSection6 {
        position
        componentName
        data {
           slices {  componentName data {  title people {  name imageUrl description } } } uid
        }
      }
    
      NestedPageSection7 {
        position
        componentName
        data {
           slices {  componentName data {  portalId formId } } uid
        }
      }
    
      }
    }
  `